/**
 * @generated SignedSource<<8a3ea804985a09fd16fac40883a2ddec>>
 * @relayHash 40704b68bebe3e6fed4e8302e3f59dc6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 55cd77831c971fbd644cb39c48791a2a1238304aa6cec30cff74bea6704d2df6

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { JourneyConfigurationPageQuery } from './JourneyConfigurationPageQuery.graphql';

const node: PreloadableConcreteRequest<JourneyConfigurationPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "55cd77831c971fbd644cb39c48791a2a1238304aa6cec30cff74bea6704d2df6",
    "metadata": {},
    "name": "JourneyConfigurationPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
